import { useState } from "react"

const useForm = (defaults) => {
  const [values, setValues] = useState(defaults)
  const [captcha, setCaptcha] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");

  const updateValue = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }

  const onCaptchaChange = value => {
    setCaptcha(value)
  }

  const submitForm = async (e) => {
    e.preventDefault()
    setLoading(true);

    const toSend = { values, captcha };
    console.log(JSON.stringify(toSend))

    try {
      const res = await fetch(`https://www.agotop.cz/.netlify/functions/sendForm`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
          "Content-Type": "application/json",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Max-Age": "2592000",
          "Access-Control-Allow-Credentials": "true",
        },
        body: JSON.stringify(toSend),
      })
    
      const text = JSON.parse(await res.text());
  
      if(res.status >=400 & res.status < 600) {
        setError(true);
        setLoading(false);
        setMsg(text.message);
      } else {
        setError(false);
        setLoading(false);
        setMsg(text.message);
        setValues({
          name: "",
          company: "",
          email: "",
          msg: "",
          phone: "",
        });
      }
    } catch (e) {
      console.log(e)
    }
  }

  return {
    updateValue,
    submitForm,
    onCaptchaChange,
    loading,
    error,
    msg,
    values
  }
}

export default useForm
