import React from "react"
import styles from "./Form.module.scss"
import Button from "../Button"
import useForm from "../../hooks/useForm"
import ReCAPTCHA from "react-google-recaptcha"

const Form = () => {
  const {
    updateValue,
    submitForm,
    onCaptchaChange,
    values,
    loading,
    error,
    msg,
  } = useForm({
    name: "",
    company: "",
    email: "",
    msg: "",
    phone: "",
  })

  return (
    <form id="form" className={styles.form} onSubmit={submitForm}>
      <div className={styles.row}>
        <input
          type="text"
          name="name"
          placeholder="Jméno*"
          value={values.name}
          onChange={updateValue}
        />
        <input
          type="text"
          name="company"
          placeholder="Firma"
          value={values.company}
          onChange={updateValue}
        />
      </div>
      <div className={styles.row}>
        <input
          type="email"
          name="email"
          placeholder="E-mail*"
          value={values.email}
          onChange={updateValue}
        />
        <input
          type="text"
          name="phone"
          placeholder="Telefon"
          value={values.phone}
          onChange={updateValue}
        />
      </div>
      <textarea
        name="msg"
        rows="10"
        placeholder="Vaše zpráva*"
        value={values.msg}
        onChange={updateValue}
      ></textarea>
      <ReCAPTCHA
        className={styles.captcha}
        sitekey="6LevrTcaAAAAAOK46RAkQuH3qJAS9dCnEm8bXg3K"
        onChange={onCaptchaChange}
      />
      <Button color="blue" type="submit" disabled={loading}>
        {loading ? "Odesílám" : "Odeslat"}
      </Button>
      {msg && (
        <div className={`${styles.msg} ${error && styles.error}`}>{msg}</div>
      )}
    </form>
  )
}

export default Form
